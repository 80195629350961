import * as React from "react";
import { Link } from "react-router-dom";

export default function RingCard({ cardImage, cardTitle, categoryId }) {
  return (
    <Link
      className="py-6 hover:scale-110 transition-transform duration-500 ease-in-out 1s cursor-pointer"
      to={`/products?&&subCategoryId=null&&categoryId=${categoryId}`}
    >
      <div className="flex flex-col items-center justify-between lg:h-[460px] md:h-[330px] h-[230px] lg:w-[300px] md:w-[225px] w-[150px] bg-white shadow-md rounded-lg overflow-hidden relative">
        <div className="w-full h-2/3 relative">
          <div className="aspect-w-3 aspect-h-4">
            <img
              src={cardImage}
              alt="ring"
              className="object-cover rounded-t-lg"
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full absolute inset-x-0 bottom-0 bg-white bg-opacity-80 backdrop-blur-md p-4">
          <h1 className="text-[10px] xs:text-[8px] md:text-[14px] lg:text-[18px]  font-bold text-gray-800">
            {cardTitle}
          </h1>
          <p className="text-[10px] xs:text-[8px] md:text-[14px] lg:text-[18px] font-semibold text-gray-500">
            {"MODELLERİ"}
          </p>
        </div>
      </div>
    </Link>
  );
}
