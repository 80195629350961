import React from "react";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";
import dlkSoftLogo from "../../images/dileksoft.svg";
const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <footer className="bg-[#E6C0B4] font-[Inter] text-white py-2 w-full">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-start">
        <div className="flex flex-col justify-center items-center lg:items-start">
          <p className="lg:text-lg text-sm">
            © {year} SARAF ÇIRAYLAR ALTIN - Tüm Hakları Saklıdır.
          </p>
          <a
            href="https://dileksoft.com"
            className=" cursor-pointer w-full lg:w-auto"
          >
            <div className="flex  w-full items-center justify-center md:justify-start">
              <p className="mr-2">Geliştirici</p>
              <img
                src={dlkSoftLogo}
                alt="Dileksoft"
                className="md:h-16 lg:h-18 h-12 hover:scale-110 transition-transform duration-500 ease-in-out 1s"
              />
            </div>
          </a>
        </div>
        <div className="my-4 lg:my-0 gap-4 w-full flex justify-center items-center">
          <a
            href="/contact"
            className="text-white mr-4 hover:scale-110 transition-transform duration-200 ease-in-out 1s"
          >
            Hakkımızda
          </a>
          <a
            href="/contactus"
            className="text-white hover:scale-110 transition-transform duration-200 ease-in-out 1s"
          >
            İletişim
          </a>
        </div>
        <div className="my-4 lg:my-0 gap-4 w-full flex justify-center items-center">
          <a
            target="_blank"
            href="https://www.instagram.com/sarrafciraylar/"
            className="text-white text-2xl hover:scale-110 transition-transform duration-200 ease-in-out 1s"
          >
            <FaInstagram />
          </a>
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=903323234243"
            className="text-white text-2xl hover:scale-110 transition-transform duration-200 ease-in-out 1s"
          >
            <FaWhatsapp />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
