import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  Drawer,
  Input,
  Button,
  Spinner,
} from "@material-tailwind/react";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import RingItems from "../components/RingItems";
import "./style.css";
import { API_URL } from "../constans/appConfig";
import { useLocation } from "react-router-dom";
import logo from "../../images/logo.svg";

function Products() {
  const [isMobile, setIsMobile] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const subCategoryId = searchParams.get("subCategoryId");
  const categoryId = searchParams.get("categoryId");

  const getAllProducts = async (append = false) => {
    try {
      setLoadingMore(true);
      const API =
        `${API_URL}/product/findProductByCategory` +
        `?categoryId=${categoryId}` +
        (subCategoryId !== null && subCategoryId !== "null"
          ? `&subCategoryId=${subCategoryId}`
          : "") +
        `&page=${page}` +
        `&size=${pageSize}`;
      const response = await fetch(API);
      const data = await response.json();
      if (append) {
        setProducts((prevProducts) => [...prevProducts, ...data.content]);
      } else {
        setProducts(data.content);
      }
      setTotalPages(data.totalPages);
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
    setLoadingMore(false);
  };

  const handleLoadMore = () => {
    if (page < totalPages - 1) {
      setPage((prevPage) => prevPage + 1);
      setLoadingMore(true);
    }
  };

  useEffect(() => {
    getAllProducts(page > 0); // Append data if page > 0
  }, [page]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    handleResize(); // initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center h-screen justify-center">
          <Spinner color="pink" className="h-32 w-32" />
          <img src={logo} alt="logo" className="h-40 w-40 mt-6" />
        </div>
      ) : (
        <div className="flex flex-col sm:flex-col font-[Inter] xs:flex-col md:flex-row h-full px-4 py-2 bg-[#FBF8F4]">
          {isMobile ? (
            <div className="w-full px-4 py-2 font-[Inter]">
              <div className="flex items-center h-full justify-between py-2">
                <Button
                  onClick={openDrawer}
                  className="bg-white flex flex-row items-center w-full gap-4 justify-center text-[Inter]"
                  color="white"
                  size="sm"
                >
                  <AdjustmentsHorizontalIcon class="h-6 w-6 text-black" />
                  <span className="text-black font-[Inter]">Filtreleme</span>
                </Button>
              </div>
              <Drawer open={isDrawerOpen} onClose={closeDrawer}>
                <Card
                  color="transparent"
                  shadow={false}
                  className="h-[calc(100vh-2rem)] w-full p-4"
                >
                  <List>
                    <div className="flex items-center justify-center py-4 gap-4">
                      <Typography variant="h5" color="blue-gray">
                        Filtrele
                      </Typography>
                    </div>

                    <hr className="my-2 border-blue-gray-50" />
                    <div className="flex items-center gap-4 px-4">
                      <Typography variant="paragraph" color="blue-gray">
                        Fiyat
                      </Typography>
                    </div>
                    <ListItem className="px-4 py-1">
                      <Input
                        label="Minumum tutar"
                        type="number"
                        onChange={(e) => setMinPrice(e.target.value)}
                        className="px-8"
                        value={minPrice}
                      />
                    </ListItem>
                    <ListItem className="px-4 py-1">
                      <Input
                        label="Maksimum tutar"
                        type="number"
                        onChange={(e) => setMaxPrice(e.target.value)}
                        className="px-8"
                        value={maxPrice}
                      />
                    </ListItem>
                    <hr className="my-2 border-blue-gray-50" />
                    <div className="px-4">
                      <Button className="w-full bg-[#E6C0B4] shadow-2xl shadow-[#E6C0B4]">
                        Filtrele
                      </Button>
                    </div>
                  </List>
                </Card>
              </Drawer>
            </div>
          ) : (
            <div className="py-6">
              <Card className="w-full font-[Inter] p-4 shadow-xl shadow-blue-gray-900/5">
                <div className="flex items-center gap-4 p-2 text-xl">
                  <span className="text-[#4B5563] font-[Inter]">Filtrele</span>
                </div>
                <div className="flex flex-col items-start gap-4 px-4">
                  <span className="text-[#4B5563] font-[Inter]">Fiyat</span>
                  <Input
                    label="Minumum tutar"
                    type="number"
                    color="pink"
                    onChange={(e) => setMinPrice(e.target.value)}
                    className="px-8"
                    value={minPrice}
                  />
                  <Input
                    label="Maksimum tutar"
                    type="number"
                    color="pink"
                    onChange={(e) => setMaxPrice(e.target.value)}
                    className="px-8"
                    value={maxPrice}
                  />
                  <Button
                    color="pink"
                    className="w-full font-[Inter] bg-[#E6C0B4]"
                  >
                    Filtrele
                  </Button>
                </div>
              </Card>
            </div>
          )}

          <div className="w-full h-full px-4">
            <div className="flex-wrap flex justify-start gap-4">
              {products?.map((product) => (
                <RingItems
                  productId={product.productId}
                  key={product.productId}
                  productName={product.productName}
                  milyem={product.productTuningMilyem}
                  gram={product.productGram}
                  price={parseFloat(product.productPrice)}
                  imageUrl={product.productImage}
                />
              ))}
            </div>
            {page < totalPages - 1 && (
              <div className="flex justify-center my-4">
                <Button
                  onClick={handleLoadMore}
                  color="pink"
                  className="font-[Inter] bg-[#E6C0B4]"
                  loading={loadingMore}
                >
                  Daha Fazla Yükle
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Products;
