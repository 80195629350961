import React from "react";
import HeadersCarousel from "./components/HeadersCarousel";
import { Typography } from "@mui/material";
import sertifika from "../images/medal 1.svg";
import memnuniyet from "../images/satisfaction 1.svg";
import cuzdan from "../images/wallet 1.svg";

export default function Contact() {
  return (
    <div className="flex flex-col  items-center w-full justify-center my-4 bg-white ">
      <div className="p-6">
        <div className=" flex p-6 shadow-xl shadow-gray-600 rounded-lg  my-6 flex-col justify-around">
          <div className="flex flex-col md:flex-row justify-around">
            <div className="flex flex-row">
              <img alt="sertificate" src={sertifika} className="md:w-48 w-24" />
              <p className="text-left text-base font-normal w-full px-6 ">
                <h3 className="text-lg font-bold py-3">Sertifikalı Ürünler</h3>
                Tüm ürünlerimiz kuyumculuk sektörünün gerektirdiği kalite
                belgeleri ile hizmetinize sunuyoruz.
              </p>
            </div>
            <div className="flex flex-row">
              <img alt="memnuniyet" src={memnuniyet} className="md:w-48 w-24" />

              <p className="text-left text-base font-normal w-full px-6 ">
                <h3 className="text-lg font-bold py-3">Memnun Müşteri</h3>
                Sarraf Çıraylar ailesi olarak birincil amacımız güleryüzlü
                müşteriler, kaliteli ve ayarı düzgün ürünler ile
                hizmetinizdeyiz.
              </p>
            </div>
            <div className="flex flex-row">
              <img alt="payment" src={cuzdan} className="md:w-48 w-24" />
              <p className="text-left text-base font-normal w-full px-6 ">
                <h3 className="text-lg font-bold py-3">Uygun Fiyat</h3>
                Temelleri 2007 yılına dayanan firmamız ilk günden bugüne kadar
                kaliteli ürün prensibiyle ilerleyişini devam ettirmektedir.
              </p>
            </div>
          </div>
        </div>
        <div className=" flex p-6 shadow-xl shadow-gray-600 rounded-lg  my-6 flex-col justify-around">
          <h1 className="text-3xl text-black font-semibold my-6 text-left">
            Firma Profili
          </h1>
          <Typography className="text-left text-xl font-medium ">
            2007 yılında Konya'da başlayan Sarraf Çıraylar, altın, pırlanta ve
            elmas ürünleri ile kuyumculuk sektörüne giriş yapmıştır. Kuyumculuk
            alanında sahip olduğu bilgi birikimi, üstün kalite anlayışı ve
            güvenilirliği ile hizmetlerini her geçen gün daha da yukarıya
            taşıyan Sarraf Çıraylar sektörel bazlı yenilikçi gelişmeleri
            yakından takip ederek sürekli bir yenilenme ile ürünlerini daima üst
            düzey bir güvenirlilik anlayışı ile hizmetinize sunmaktadır.
          </Typography>
          <h2 className="text-2xl text-black font-semibold my-6 text-left">
            Vizyon & Misyon
          </h2>
          <h3 className="text-left text-lg font-medium">Vizyon</h3>
          <Typography className="text-left text-lg font-normal">
            Altın ve mücevher ürünlerinde güvenilir ticaret kuralını
            benimseyerek, ülke ekonomisine ve aile tasarrufuna katkı sağlamak.
          </Typography>
          <h3 className="text-left text-lg font-medium">Misyon</h3>
          <Typography className="text-left text-lg font-normal">
            Sunduğumuz ürünler ile müşterilerimize ayrıcalık sağlamak.
          </Typography>
          <h2 className="text-2xl text-black font-semibold my-6 text-left">
            Kalite Politikası
          </h2>
          <Typography className="text-left text-lg font-normal">
            Kaliteli bir hizmetin temelinde güvenilirlik bulunmaktadır. Gerekli
            güven düzeyinin sağlanması için güçlü, kurumsal ve dürüst bir iş
            ilişkisi sağlanmalıdır. Bu bağlamda, sahip olduğumuz uzman kadromuz
            ile belirlediğimiz firma içi kalite standartlarımızı tüm ekip
            arkadaşlarımızın sorumluluğu ile değerli müşterilerimize sağlıyoruz.
            Kalite standartlarımızın daima yukarılara taşınması için, sektörel
            bazlı yenilikleri yakından takip ederek, kendimizi TSE belgesi ve
            darphane kalite standartları noktasında sürekli yukarıya doğru
            ilerliyoruz.
          </Typography>
        </div>
      </div>
    </div>
  );
}
