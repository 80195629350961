import React from "react";
import HeadersCarousel from "./components/HeadersCarousel";
import { API_URL } from "./constans/appConfig";
import { useEffect, useState } from "react";
import { Spinner } from "@material-tailwind/react";
import RandomCarousel from "./components/randomCarousel";
import logo from "../images/logo.svg";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [carouselLoading, setCarouselLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState([]);

  const getHomePageRandomCategory = async () => {
    try {
      const response = await fetch(
        `${API_URL}/homePageCategory/getAllHomePageCategoryDto`
      );
      const data = await response.json();
      setFetchedData(data);
      setLoading(false); // Tüm veriler alındıktan sonra loading durumunu false olarak ayarlayın
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getHomePageRandomCategory();
  }, []);

  return (
    <>
      {loading && carouselLoading ? (
        <div className="flex flex-col items-center h-screen justify-center items-center">
          <Spinner color="pink" className="h-32 w-32" />
          <img src={logo} alt="logo" className="h-40 w-40 mt-6" />
        </div>
      ) : (
        <div className="flex flex-col font-[Inter] items-center gap-8">
          <HeadersCarousel setCarouselLoading={setCarouselLoading} />
          {fetchedData.map((category, index) => (
            <RandomCarousel key={index} category={category} />
          ))}
        </div>
      )}
    </>
  );
};

export default Home;
