import logosvg from "../../images/logo.svg";
import React, { useState, useEffect } from "react";
import "./style.css";
import { Button } from "@material-tailwind/react";
import { Bars3Icon } from "@heroicons/react/20/solid";
import {
  Drawer,
  Card,
  List,
  Accordion,
  ListItem,
  AccordionHeader,
  ListItemPrefix,
  Typography,
  AccordionBody,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  DocumentTextIcon,
  ChevronRightIcon,
  PhoneArrowUpRightIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import { API_URL } from "../constans/appConfig";

export default function Navbar() {
  const [isMobile, setIsMobile] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openCategoryId, setOpenCategoryId] = useState(
    sessionStorage.getItem("openCategoryId") || null
  );
  const [openSubcategoryId, setOpenSubcategoryId] = useState(
    sessionStorage.getItem("openSubcategoryId" || null)
  );
  const [goldPrice, setGoldPrice] = useState(null);
  const [diamondPrice, setDiamondPrice] = useState(null);

  const [categories, setCategories] = useState([]);
  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };
  const handleOpenCategory = (categoryId) => {
    setOpenCategoryId(openCategoryId === categoryId ? null : categoryId);
  };

  const handleOpenSubcategory = (subcategoryId) => {
    setOpenSubcategoryId(
      openSubcategoryId === subcategoryId ? null : subcategoryId
    );
  };

  const handleSelectSubcategory = (subcategoryId) => {
    window.location.href = `/products?subCategoryId=${subcategoryId}&&categoryId=${openCategoryId}`;
  };
  const handleSelectCategory = (categoryId) => {
    setOpenSubcategoryId(null);
    window.location.href = `/products?subCategoryId=null&&categoryId=${categoryId}`;
  };
  const getAllPrices = async () => {
    try {
      const response = await fetch(API_URL + "/price/getAllPrice");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      } else {
        const data = await response.json();
        const prices = data?.content || [];
        const goldPrice = prices.find((price) => price.priceId === "gram");
        const diamondPrice = prices.find((price) => price.priceId === "dolar");
        setGoldPrice(goldPrice);
        setDiamondPrice(diamondPrice);
      }
    } catch (error) {
      console.error("Failed to fetch prices:", error);
      // İsteğe bağlı olarak, kullanıcı dostu bir hata mesajı gösterin
    }
  };

  const getAllCategories = async () => {
    try {
      const response = await fetch(
        API_URL + "/fullCategory/getAllFullCategory"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
      // İsteğe bağlı olarak, kullanıcı dostu bir hata mesajı gösterin
    }
  };

  useEffect(() => {
    getAllCategories();
    getAllPrices();
  }, []);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 1220);
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="App font-[Inter]">
      <div className="flex flex-row justify-end items-center gap-4 p-1 px-4 bg-[#FFDCD2] drop-shadow-md">
        <span className="text-gray-800 md:text-[12px] lg:text-[14px] sm:text-[10px] xs:text-[6px] text-[8px]">
          Altın Fiyatı: {goldPrice?.price} ₺
        </span>
        <span className="text-gray-800 md:text-[12px] lg:text-[14px] sm:text-[10px] xs:text-[6px] text-[8px]">
          |
        </span>
        <span className="text-gray-800 md:text-[12px] lg:text-[14px] sm:text-[10px] xs:text-[6px] text-[8px]">
          Dolar Fiyatı: {diamondPrice?.price} ₺
        </span>
      </div>
      {!isMobile ? (
        <nav className="navbar px-4 flex flex-row justify-around w-full ">
          <a href="/">
            <img src={logosvg} alt="Logo" className="w-32" />
          </a>
          <div
            onMouseLeave={() => {
              setOpenCategoryId(null);
              setOpenSubcategoryId(null);
            }}
            className="flex flex-row"
          >
            {categories.map((category) => (
              <div
                key={category.categoryId}
                className="dropdown"
                style={{ position: "relative" }}
              >
                <div
                  className="nav-link dropdown-toggle cursor-pointer "
                  onMouseEnter={() => handleOpenCategory(category.categoryId)}
                  onClick={() => handleSelectCategory(category.categoryId)}
                >
                  {category.categoryName}
                </div>
                {openCategoryId === category.categoryId && (
                  <div
                    className="dropdown-menu"
                    style={{
                      display: "block",
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      backgroundColor: "#fff",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      zIndex: 999,
                      minWidth: "200px",
                    }}
                    onMouseEnter={() => setOpenCategoryId(category.categoryId)}
                  >
                    <div className="submenu">
                      {category.subcategories.map((subcategory, index) => (
                        <div key={subcategory.subcategoryId}>
                          <div
                            className="nav-link subcategory-toggle cursor-pointer flex items-center flex-row"
                            onMouseEnter={() =>
                              handleOpenSubcategory(subcategory.subcategoryId)
                            }
                            onClick={() =>
                              handleSelectSubcategory(subcategory.subcategoryId)
                            }
                          >
                            {subcategory.subcategoryName}{" "}
                            {openSubcategoryId ===
                              subcategory.subcategoryId && (
                              <ChevronDownIcon className="w-4" />
                            )}
                          </div>

                          {/* Son alt kategori değilse bir ayar koyma */}
                          {index !== category.subcategories.length - 1 && (
                            <hr className="my-2 border-blue-gray-50" />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div>
            <a href="/contact" className="nav-link">
              HAKKIMIZDA
            </a>
            <a href="/contactus" className="nav-link">
              İLETİŞİM
            </a>
          </div>
        </nav>
      ) : (
        <div className="w-full px-4 py-2 ">
          <div className="flex items-center h-full justify-between items-center pt-4 px-4">
            <a href="/">
              <img src={logosvg} alt="logo" className="h-12 px-8" />
            </a>
            <Button
              onClick={openDrawer}
              className="flex shadow-xl items-center gap-4 justify-start rounded-md"
              color="white"
              size="sm"
            >
              <Bars3Icon className="h-6 w-6 text-black mr-1" />
              <span className="text-black font-[Inter]">MENU</span>
            </Button>
          </div>
          <Drawer open={isDrawerOpen} onClose={closeDrawer}>
            <Card
              color="transparent"
              shadow={false}
              className="h-[calc(100vh-2rem)] w-full p-4"
            >
              <div className="mb-2 flex items-center gap-4 p-4">
                <a href="/">
                  <img src={logosvg} alt="logo" className="h-16" />
                </a>
              </div>

              <List>
                {categories.map((category) => (
                  <React.Fragment key={category.categoryId}>
                    {category.subcategories.length > 0 ? (
                      <Accordion
                        open={openCategoryId === category.categoryId}
                        icon={
                          <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${
                              openCategoryId === category.categoryId
                                ? "rotate-180"
                                : ""
                            }`}
                          />
                        }
                      >
                        <ListItem
                          className="p-0"
                          selected={openCategoryId === category.categoryId}
                        >
                          <AccordionHeader
                            onClick={() =>
                              handleOpenCategory(category.categoryId)
                            }
                            className="border-b-0 p-3"
                          >
                            <ListItemPrefix>
                              <Squares2X2Icon className="h-5 w-5" />
                            </ListItemPrefix>
                            <Typography
                              color="blue-gray"
                              className="mr-auto font-normal font-[Inter] text-sm"
                            >
                              {category.categoryName}
                            </Typography>
                          </AccordionHeader>
                        </ListItem>
                        <AccordionBody className="py-1">
                          <List className="p-0">
                            <a
                              onClick={() =>
                                handleSelectCategory(category.categoryId)
                              }
                            >
                              <ListItem className="mr-auto font-normal font-[Inter] text-sm">
                                <ListItemPrefix>
                                  <ChevronRightIcon
                                    strokeWidth={3}
                                    className="h-3 w-5"
                                  />
                                </ListItemPrefix>
                                TÜMÜ
                              </ListItem>
                            </a>
                            {category.subcategories.map((subcategory) => (
                              <a
                                key={subcategory.subcategoryId}
                                onClick={() =>
                                  handleSelectSubcategory(
                                    subcategory.subcategoryId
                                  )
                                }
                              >
                                <ListItem className="mr-auto font-normal font-[Inter] text-sm">
                                  <ListItemPrefix>
                                    <ChevronRightIcon
                                      strokeWidth={3}
                                      className="h-3 w-5"
                                    />
                                  </ListItemPrefix>
                                  {subcategory.subcategoryName}
                                </ListItem>
                              </a>
                            ))}
                          </List>
                        </AccordionBody>
                      </Accordion>
                    ) : (
                      <ListItem
                        key={category.categoryId}
                        onClick={() =>
                          handleSelectCategory(category.categoryId)
                        }
                        className="mr-auto font-normal font-[Inter] text-sm"
                      >
                        <ListItemPrefix>
                          <Squares2X2Icon className="h-5 w-5" />
                        </ListItemPrefix>
                        {category.categoryName}
                      </ListItem>
                    )}
                  </React.Fragment>
                ))}

                <hr className="my-2 border-blue-gray-50" />
                <a href="/contact">
                  <ListItem className="mr-auto font-normal font-[Inter] text-sm">
                    <ListItemPrefix>
                      <PhoneArrowUpRightIcon className="h-5 w-5" />
                    </ListItemPrefix>
                    İLETİŞİM
                  </ListItem>
                </a>
                <a href="/contactus">
                  <ListItem className="mr-auto font-normal font-[Inter] text-sm">
                    <ListItemPrefix>
                      <DocumentTextIcon className="h-5 w-5" />
                    </ListItemPrefix>
                    HAKKIMIZDA
                  </ListItem>
                </a>
              </List>
            </Card>
          </Drawer>
        </div>
      )}
    </div>
  );
}
