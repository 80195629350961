import RingItems from "../RingItems";
import RingCard from "../RingCard";
import { API_URL } from "../../constans/appConfig";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function randomCarousel({ category }) {
  const categoryData = category;
  const categoryProducts = categoryData?.products;
  const categoryDescription = categoryData?.category;
  return (
    <>
      <div className="flex flex-col items-center justify-center w-full md:px-12 px-4">
        {categoryData?.homePageCategoryName && (
          <div className="flex w-full flex-row items-center justify-center gap-2">
            <hr className="w-5/12 border-1 border-gray-300" />
            <span className="font-normal  text-gray-800 lg:text-2xl md:text-xl sm:text-md text-md">
              {categoryData?.homePageCategoryName}
            </span>
            <hr className="w-5/12 border-1 border-gray-300 " />
          </div>
        )}
        <div className="flex w-full  flex-row items-center justify-center">
          <div className="flex lg:w-2/12 md:w-2/6 w-1/2  justify-center items-center">
            <RingCard
              categoryId={categoryDescription?.categoryId}
              cardImage={API_URL + "/" + categoryData?.imagePath}
              cardTitle={categoryData?.homePageCategoryName}
            />
          </div>
          <div className="flex lg:w-10/12 md:w-4/6 w-1/2 justify-center items-center">
            <Swiper
              spaceBetween={
                window.innerWidth > 1920 ? 3 : window.innerWidth > 1650 ? 2 : 1
              }
              slidesPerView={
                window.innerWidth > 1920
                  ? 4
                  : window.innerWidth > 1650
                  ? 3
                  : window.innerWidth > 1600
                  ? 2
                  : window.innerWidth > 868
                  ? 2
                  : 1
              }
              loop={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              className="w-full flex justify-center items-center"
              grabCursor={true}
              modules={[Autoplay, Pagination, Navigation]}
              breakpoints={{
                200: {
                  slidesPerView: 1,
                },
                500: {
                  slidesPerView: 1,
                },
                676: {
                  slidesPerView: 2,
                },
                868: {
                  slidesPerView: 2,
                },
                1250: {
                  slidesPerView: 3,
                },
                1650: {
                  slidesPerView: 4,
                },
                1950: {
                  slidesPerView: 4,
                },
              }}
            >
              {categoryProducts?.map(
                (product) =>
                  product.productVisible === true && (
                    <SwiperSlide
                      className="md:py-14 py-6 md:px-8 flex justify-center items-center"
                      key={product.productId}
                    >
                      <RingItems
                        productId={product.productId}
                        productName={product.productName}
                        milyem={product.productTuningMilyem}
                        gram={product.productGram}
                        price={parseFloat(product.productPrice)}
                        imageUrl={product.productImage}
                      />
                    </SwiperSlide>
                  )
              )}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}
