import { Carousel } from "@material-tailwind/react";
import { API_URL } from "../constans/appConfig";
import React, { useEffect, useState } from "react";

export default function HeadersCarousel({ setCarouselLoading }) {
  const [sliderImages, setSliderImages] = useState([]);
  const getSliderImages = async () => {
    const response = await fetch(
      `${API_URL}/image/getImagesBySectionDto?section=slider&active=true&size=50000`
    );
    const data = await response.json();
    setSliderImages(data);
    setCarouselLoading(false);
  };
  useEffect(() => {
    getSliderImages();
  }, []);
  return (
    <Carousel
      navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
          {new Array(length).fill("").map((_, i) => (
            <span
              key={i}
              className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                activeIndex === i ? "bg-white w-8" : "bg-white/50 w-4"
              }`}
              onClick={() => setActiveIndex(i)}
            />
          ))}
        </div>
      )}
    >
      {sliderImages.map((item) => (
        <div key={item.imageId} className="w-full">
          <img
            src={API_URL + "/" + item.imagePath}
            alt="slider"
            className="w-full h-auto"
            style={{ aspectRatio: "1920 / 400" }}
          />
        </div>
      ))}
    </Carousel>
  );
}
