import React from "react";
import HeadersCarousel from "./components/HeadersCarousel";
import location from "../images/location.svg";
import phone from "../images/phone.svg";
import mail from "../images/email.svg";
import Mapcomponent from "./components/Mapcomponent/Mapcomponent";

export default function Communication() {
  return (
    <div className="flex flex-col items-center w-full h-full md:h-[800px] justify-center my-4  ">
      <div className="py-6 px-4 md:px-16 flex flex-col gap-4 w-full">
        <div className="flex w-full  gap-4 shadow-xl rounded-lg shadow-gray-300 ">
          <Mapcomponent />
        </div>
        <div className="flex w-full flex-col sm:flex-row  gap-4 shadow-xl p-6 bg-white shadow-gray-400 rounded-lg  ">
          <a
            href="https://maps.app.goo.gl/D6qVNV9gYK5E2u3m7"
            target="_blank"
            className="cursor-pointer flex flex-row w-full md:justify-center justify-start items-center mb-4 sm:mb-0 hover:scale-110 transition-transform duration-200 ease-in-out 1s"
          >
            <img alt="location" src={location} className="md:w-16 w-8" />
            <div className="w-full flex flex-col md:gap-2 items-center">
              <h3 className="text-lg font-bold py-1">ADRES</h3>
              <p className="font-normal text-base">
                Alavardı Mah. Yaka Cad. No: 10/A Meram / KONYA
              </p>
            </div>
          </a>
          <a
            href="tel:03323234243"
            target="_blank"
            className="cursor-pointer flex flex-row w-full md:justify-center justify-start items-center mb-4 sm:mb-0 hover:scale-110 transition-transform duration-200 ease-in-out 1s"
          >
            <img alt="phone" src={phone} className="md:w-16 w-8" />
            <div className="w-full flex flex-col md:gap-2 items-center">
              <h3 className="text-lg font-bold py-1">TELEFON</h3>
              <p className="font-normal text-base">(0332) 323 42 43</p>
            </div>
          </a>
          <a
            href="mailto:bilgi@sarrafciraylar.com"
            target="_blank"
            className="cursor-pointer flex flex-row w-full md:justify-center justify-start items-center mb-4 sm:mb-0 hover:scale-110 transition-transform duration-200 ease-in-out 1s"
          >
            <img alt="mail" src={mail} className="md:w-16 w-8" />
            <div className="w-full flex flex-col md:gap-2 items-center">
              <h3 className="text-lg font-bold py-1">E-MAIL</h3>
              <p className="font-normal text-base">bilgi@sarrafciraylar.com</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
