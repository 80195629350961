import "./App.css";
import Navbar from "./pages/Navbar/Navbar";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Products from "./pages/Product/Products";
import ProductDetail from "./pages/Product/ProductDetail";
import Contact from "./pages/Contact";
import Communication from "./pages/Communication";
import Footer from "./pages/Footer/Footer";
import { useState } from "react";
import { Button } from "@material-tailwind/react";
import { FaWhatsapp } from "react-icons/fa";

function App() {
  const [hovered, setHovered] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(4);
  const handleMouseEnter = () => {
    setHovered(true);
    setButtonWidth(10); // Yavaşça genişletmek için hedef genişlik
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setButtonWidth(4); // Yavaşça daraltmak için hedef genişlik
  };
  const handleWhatsappMessage = () => {
    const apiUrl = "https://wa.me/903323234243";
    window.open(`${apiUrl}`, "_blank");
  };

  return (
    <div className="App h-full bg-[#FBF8F4] font-[Inter]">
      <BrowserRouter>
        <div className="fixed bottom-3 right-4" style={{ zIndex: 999 }}>
          <Button
            variant="gradient"
            color="green"
            style={{
              width: `${buttonWidth}rem`,
              height: "4rem",
              transition: "width 1s ease",
            }}
            className={`font-[Inter] rounded-full shadow-2xl shadow-blue-gray-900 flex flex-row items-center ${
              hovered ? "justify-end" : "justify-center"
            }`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {hovered ? (
              <a
                onClick={handleWhatsappMessage}
                target="_blank"
                rel="noreferrer"
                className="flex flex-row items-center justify-center gap-2"
              >
                <FaWhatsapp className={`h-6 w-6 text-white`} />
                <span className="text-white text-sm">Whatsapp</span>
              </a>
            ) : (
              <FaWhatsapp className={`h-6 w-6 text-white`} />
            )}
          </Button>
        </div>

        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/detail" element={<ProductDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contactus" element={<Communication />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
