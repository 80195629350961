import React from "react";
import { MapContainer as LeafletMap, TileLayer, Marker } from "react-leaflet";
import L from "leaflet"; // Import the Leaflet library
import "leaflet/dist/leaflet.css";
import sarrafMarker from "../../../images/sarraf-Icon.svg";
const Map = () => {
  const position = [37.87092318695236, 32.456029324661564];

  // Import the default Leaflet marker icon
  const defaultIcon = new L.Icon({
    iconUrl: sarrafMarker,
    iconSize: [40, 40],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
  });

  // Set the default icon for markers
  L.Marker.prototype.options.icon = defaultIcon;

  const Jawg_Streets = (
    <TileLayer
      url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
      minZoom={0}
      maxZoom={20}
      subdomains={"abcd"}
      attribution={
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      }
      ext="png"
    />
  );

  return (
    <LeafletMap
      attributionControl={false}
      center={position}
      className="rounded-lg"
      zoom={14}
      style={{ height: "400px", width: "100%" }}
    >
      {Jawg_Streets}
      <Marker position={position} />
      <div className="leaflet-bottom leaflet-right">
        <div className="leaflet-control-attribution leaflet-control">
          <a className="bg-transparent" href="https://www.dileksoft.com">
            {"Dileksoft"}
          </a>
        </div>
      </div>
    </LeafletMap>
  );
};

const MapComponent = () => {
  return <Map />;
};

export default MapComponent;
