import * as React from "react";
import { API_URL } from "../constans/appConfig";

export default function RingItems({
  productId,
  productName,
  price,
  imageUrl,
  milyem,
}) {
  const handlePriceFormat = (price) => {
    const ceiledPrice = Math.ceil(price);
    const formattedPrice = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
    }).format(ceiledPrice);
    return formattedPrice;
  };

  return (
    <a href={"/products/detail?productId=" + productId} className="py-6 ">
      <div className="hover:scale-110 transition-transform duration-500 ease-in-out 1s flex flex-col items-center justify-between lg:h-[460px] md:h-[330px] h-[230px] lg:w-[300px] md:w-[225px] w-[150px] bg-white shadow-md rounded-lg overflow-hidden relative">
        <div className="w-full h-2/3 relative">
          <div className="aspect-w-3 aspect-h-4">
            <img
              src={API_URL + "/" + imageUrl}
              alt="ring"
              className="object-cover rounded-t-lg"
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full absolute inset-x-0 bottom-0 bg-white bg-opacity-50 backdrop-blur-md p-2">
          <h1 className="text-[10px] xs:text-[8px] md:text-[14px] lg:text-[18px] font-bold text-gray-800">
            {productName.toLocaleUpperCase("tr-TR").slice(0, 20)}
          </h1>

          <p className="text-[10px] xs:text-[8px] md:text-[14px] lg:text-[18px] font-semibold text-gray-600">
            {milyem}
          </p>
          <p className="text-[10px] xs:text-[8px] md:text-[14px] lg:text-[18px] font-semibold text-gray-600">
            {handlePriceFormat(price)}
          </p>
        </div>
      </div>
    </a>
  );
}
