import React from "react";
import { useLocation } from "react-router-dom";
import { API_URL } from "../constans/appConfig";
import { useEffect } from "react";
import defaultProductImage from "../../images/loadingImage.svg";
import { Spinner } from "@material-tailwind/react";
import { FaWhatsapp } from "react-icons/fa";

export default function ProductDetail() {
  const location = useLocation();
  const [product, setProduct] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const handleWhatsappMessage = () => {
    const apiUrl = "https://wa.me/903323234243";
    const productLink = window.location.href;
    const message = `${productLink}
     Merhaba, adresindeki ürün hakkında bilgi almak istiyorum.`;
    window.open(`${apiUrl}?text=${message}`, "_blank");
  };

  useEffect(() => {
    getProduct();
  }, []);
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get("productId");
  const getProduct = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        API_URL + `/product/findById?productId=${productId}`
      );
      const data = await response.json();
      setProduct(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePriceFormat = (price) => {
    const ceiledPrice = Math.ceil(price);
    const formattedPrice = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
    }).format(ceiledPrice);
    return formattedPrice;
  };

  return (
    <div className="flex flex-col md:h-[800px] lg:h-[800px] xl:h-[800px] h-full font-[Inter] md:flex-row justify-center items-center gap-6 md:gap-0 md:justify-around px-6 md:px-0 py-8 ">
      <img
        src={
          loading ? defaultProductImage : API_URL + "/" + product?.productImage
        }
        alt="product"
        className="h-[480px] rounded-xl shadow-xl"
        style={{ aspectRatio: "2/3" }}
      />
      <div className="md:w-7/12 md:h-[480px] lg:h-[480px] w-full h-full px-4 md:px-8 py-8 shadow-xl rounded-xl bg-white">
        <div
          className="flex flex-col items-center justify-center gap-4  h-full"
          style={{ width: "100%", padding: "4rem" }}
        >
          <h1 className="md:text-2xl lg:text-3xl xs:text-md text-lg font-bold text-black">
            {product?.productName}
          </h1>
          {product?.productPrice && (
            <h2 className="md:text-lg lg:text-xl xs:text-md text-lg font-bold text-black">
              {handlePriceFormat(product?.productPrice)}
            </h2>
          )}
          {product.productGold === true ? (
            <div className="flex flex-col gap-4 text-black">
              <span>Gram : {product?.productGram} gr</span>
              <span>Kategori : {product?.productCategoryFk?.categoryName}</span>
              {product?.productSubCategoryFk?.subcategoryId && (
                <span>
                  Alt Kategori :{product?.productSubCategoryFk?.subcategoryName}
                </span>
              )}
              <span>Açıklama</span>
              <span>{product?.productDescription}</span>
            </div>
          ) : product.productGold === false ? (
            <div className="flex flex-col gap-4 text-black">
              <span>Kategori : {product?.productCategoryFk?.categoryName}</span>
              <span> Gram : {product?.productGram} gr</span>
              <span>Karat : {product?.productTuningMilyem}</span>
              <span>Açıklama</span>
              <span>{product?.productDescription}</span>
            </div>
          ) : (
            <Spinner color="blue" className="w-48" />
          )}
          <span className="md:text-md lg:text-lg xs:text-xs text-base py-8 italic font-thin text-gray-600 ">
            *Ürünlerimiz sadece mağaza teslimidir. Online satışımız
            bulunmamaktadır.
          </span>
          <button
            onClick={handleWhatsappMessage}
            className="font-[Inter] flex flex-row items-center justify-center gap-2 hover:scale-110 transition-transform duration-500 ease-in-out 1s bg-green-700 text-white p-2 rounded-lg shadow-md hover:shadow-xl"
          >
            <FaWhatsapp className="sm:text-2xl text-4xl" />
            <span className="text-md">Ürün Hakkında Bilgi Almak İstiyorum</span>
          </button>
        </div>
      </div>
    </div>
  );
}
